/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-concat */
/* eslint-disable prefer-template */
/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import {
  NumberedListWithImage,
  PlantAndCoffee,
  Celebrate,
  ReviewsWithSocial,
  SocialProof,
  LoanGuideConvo,
  TextAndImageV2
} from '@beelineloans/cx-library/src/components/layout/panels';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Box from '@beelineloans/cx-library/src/images/box.png';
import { Button, ButtonText, ButtonCircle, ButtonEmail } from '@beelineloans/cx-library/src/components/buttons';
import { Paragraph } from '@beelineloans/cx-library/src/components/typography';
import VideoModal from '@beelineloans/cx-library/src/components/modals/VideoModal';
import investorReviews from '@beelineloans/cx-library/src/utils/investor_reviews';
import FaqList from '@beelineloans/cx-library/src/components/layout/panels/FaqList';
import PhoneHomeRun from '@beelineloans/cx-library/src/images/phone-home-run.png';
import { applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import ChatHeader from '../../../components/chat/ChatHeader';

const NumberedListWithImageStyled = styled(NumberedListWithImage)`
  .list_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .list_left_image {
      margin-left: 0;
      margin-bottom: 110px;
    }
  }
`;

const AskButton = styled(ButtonEmail)`
  margin-top: 40px;
`;

const HomeRun = styled(TextAndImageV2)`
  .container-inner {
    padding-bottom: 0;
  }

  .image-wrap {
    position: relative;
    top: 4px;
  }

  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    .container-inner {
      padding-top: 0;
      padding-bottom: 0;
    }

    .text-wrap {
      padding-top: 152px;
      padding-bottom: 125px;
    }

    .image-wrap {
      top: 0px;
    }
  }
`;

const ChatTemplate = styled(NavOnlyTemplate)`
  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 767px) {
    ${(props) =>
      props.$fixed &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        box-shadow: 0 0px 16px rgba(0, 0, 76, 15%);
      `};
  }
`;

const Chat = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const handleVideoModalClosed = () => {
    setVideoModalOpen(false);
  };

  const onWatchClick = () => {
    setVideoModalOpen(true);
  };

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.LoansForInvestor
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.OutsideTheBoxInvestor
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.CertaintyUpfront
    },
    {
      number: 4,
      noBorder: true,
      ...SiteCopy.numberedList.LoanGuideInvest
    }
  ];

  const templateOptions = { options: { background: Theme.colours.background.nude, navigationProps: { signInText: 'Apply', signInEndpoint: applyEndpoint } } };

  return (
    <ChatTemplate headerProps={templateOptions} $fixed={isHeaderFixed}>
      <SEO
        path={CONST.LINKS.MAIN.HOME}
        title="Loans to help anyone build a real estate empire."
        description="The most effortless application ever. Loans for all situations — simple or tricky."
        antiFlicker
      />
      <ChatHeader onHeaderFixed={setIsHeaderFixed} />
      <SocialProof dark type="partners" />
      <NumberedListWithImageStyled
        sideHeading={
          <>
            Pain-free investment
            <br />
            property loans.
          </>
        }
        items={numberedList}
      />
      <FaqList />
      <TextAndImageV2
        h2={
          <>
            Got an outside of the box case?
            <br />
            No problem.
          </>
        }
        image={{
          src: Box,
          mobileWidth: '315px',
          title: 'Got an outside of the box case? No problem.',
          desktopWidth: '483px',
          alignment: 'center'
        }}
        left
      >
        <Paragraph>
          Maybe you’ve got the kind of situation other lenders put in the ‘too hard’ basket.
          <br />
          <br />
          So the thought of going through an application gives you hives — you just want to know if you can get a loan, or not.
          <br />
          <br />
          Good news! We embrace the quirks.
          <br />
          <br />
          We’ve got dozens of different loan types, and Loan Guides who aren’t afraid to use them.
          <br />
          <br />
          Get in touch with a Loan Guide who’ll go through their bag of tricks to find a loan that works for you.
        </Paragraph>
        <AskButton type="investor" asButton>
          Ask a Loan Guide
        </AskButton>
      </TextAndImageV2>
      <ReviewsWithSocial
        googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM}
        bbbRating={process.env.GATSBY_BBB_REVIEW_NUM}
        showRating={false}
        title="These investors know what’s up..."
        text="See why other savvy investors trust us for speedy, pain-free loans."
        showSideImage
        items={investorReviews}
      />
      <LoanGuideConvo
        heading={
          <>
            You get a dedicated Loan Guide who&apos;s well
            <br />
            versed in rental property loans.
          </>
        }
      >
        <>
          Call, text, email, DM, carrier pigeon — how you reach your&nbsp;
          <ButtonText to={CONST.LINKS.MAIN.LOAN_GUIDE} selected>
            Loan Guide
          </ButtonText>{' '}
          is up to you.
        </>
      </LoanGuideConvo>
      <HomeRun
        h2="The Home Run."
        image={{
          src: PhoneHomeRun,
          mobileWidth: '315px',
          title: 'The Home Run.',
          desktopWidth: '476px',
          alignment: 'bottom'
        }}
        backgroundColor={Theme.colours.background.tan}
        left
      >
        <Paragraph>
          Everything you need to know about real estate investing.
          <br />
          <br />
          Tune in as we demystify topics like how to qualify for an investment loan, landlording tips and tricks, and how to build a real estate portfolio.
          <br />
          <br />
          We present each session alongside a topic expert – so you’re getting insights from people who have a mountain of experience.
          <br />
          <br />
          Tune in to monthly, online sessions and walk away with clarity on how to achieve your real estate investment goals and that sweet, sweet financial freedom.
        </Paragraph>
        <ButtonCircle onClick={onWatchClick}>Watch</ButtonCircle>
      </HomeRun>
      <Celebrate
        useHatImage
        popOnScroll={false}
        heading={
          <>
            Can we get a ‘hell yeah’ for
            <br />
            financial freedom?
          </>
        }
      >
        <Paragraph>
          There’s nothing like the feeling of taking steps towards financial freedom — and we get it. We know the loan itself is less important than taking you where you want to
          be.
          <br />
          <br />
          And where’s that?
          <br />
          <br />
          Kicking back watching your portfolio expand on a Tuesday morning while you cheers yourself at the movie theater.
          <br />
          <br />
          So, let’s get the loan out of the way — so you can watch that rent roll in.
        </Paragraph>
      </Celebrate>
      <PlantAndCoffee
        heading="WTF is DSCR? Use the property’s income to get the loan — not yours."
        buttonChildren={<Button to={CONST.LINKS.MAIN.INVESTORS.DSCR}>Find out more</Button>}
      >
        <Paragraph light fade>
          Don’t limit your portfolio’s growth to your personal income. Know exactly where you stand
          <br />
          with loan options upfront — then go play real life monopoly.
        </Paragraph>
      </PlantAndCoffee>
      <VideoModal open={videoModalOpen} closeCallback={handleVideoModalClosed} videoType="invest" voomly />
    </ChatTemplate>
  );
};

Chat.propTypes = {};

Chat.defaultProps = {};

export default Chat;
