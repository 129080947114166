/* eslint-disable no-use-before-define */
// This component got a bit out of control, need to split it up into smaller components

import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { Headline, SubHeadline, Legal, Breakpoint, H3 } from '@beelineloans/cx-library/src/components/typography';
import InputField from '@beelineloans/cx-library/src/components/forms/fields/InputField';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { ButtonIcon, Button, ButtonText, HeroButton, ButtonOutline } from '@beelineloans/cx-library/src/components/buttons';
import RatingsBlock from '@beelineloans/cx-library/src/components/SocialStarRating';
import { post } from '@beelineloans/cx-library/src/utils/fetch';
import { setLocalStorage, getLocalStorage, applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import { push } from '@beelineloans/cx-library/src/utils/dataLayer';
import Modal from '@beelineloans/cx-library/src/components/modals/Modal';
import { ChatHistory } from './ChatHistory';
import LoadingCursor from './LoadingCursor';
import { MessageItem } from './ChatMessage';
import ChatQuoteQuestion from './ChatQuoteQuestion';
import PlantImg from '../../../../shared/images/plant.png';
import PaperPlaneImg from '../../../../shared/images/paper-plane.svg';
import TrashImg from '../../../../shared/images/trash.svg';
import ArrowDarkLeft from '../../../../shared/images/arrow-dark-left.svg';
import ArrowLightRight from '../../../../shared/images/arrow-light-right.svg';
import TextStreaming from './TextStreaming';
import logoAnimationData from './beelineAnimation.json';

const ChatWrap = styled.div`
  min-height: calc(100vh - 95px);
  padding: 0;
  background-color: ${(props) => props.theme.colours.background.nude};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 720px;
  position: relative;
  overflow: initial;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    ${(props) =>
      props.$headerFixed &&
      css`
        padding-top: 95px;
      `}
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    min-height: calc(100vh - 161px);
  }
`;

const Plant = styled.img`
  display: none;
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    display: initial;
    width: 414px;
    height: 744px;
    position: absolute;
    left: -10vw;
    bottom: -63px;
    z-index: 0;
  }
`;

const SubHeading = styled(SubHeadline)`
  text-align: left;
  padding-bottom: 30px;
  padding-top: 0;
  margin-top: 0;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    text-align: center;
    margin: 10px 0;
  }
`;

const ChatContainer = styled(Container)`
  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `}
`;
const HeadlineContainer = styled(ChatContainer)`
  .container-inner {
    padding-top: 30px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding-top: 60px;
      align-items: center;
    }

    ${Headline} {
      @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
        margin-bottom: 30px;
        text-align: center;
      }
    }
    ${SubHeadline} {
      margin-right: 0;
    }
  }
`;

const HistoryWrap = styled.div`
  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `}

  // max-height: 725px;
  transition:       all .3s ease-in-out;
  // overflow-y: scroll;
  // overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colours.background.grey};
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colours.background.grey};
  }
  scroll-behavior: smooth;

  ${(props) =>
    props.$headerFixed &&
    css`
      margin-top: 95px;
    `}
`;

const AILegal = styled(Legal)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    font-size: 10px;
    line-height: 12px;
  }
  margin-top: 10px !important;
  text-align: center;
`;

const MessageContainer = styled(ChatContainer)`
  z-index: 50;
  padding: 0 0 35px;
  position: relative;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    ${(props) =>
      props.$chatting &&
      css`
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffdacd;
        box-shadow: 0 0px 15px rgba(0, 0, 76, 15%);
        padding: 0;
      `}
  }

  .container-inner {
    padding-bottom: 0;
    padding-top: 24px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 640px;

    form {
      width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const MessageBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  gap: 24px;
  height: 48px;
  isolation: isolate;
  margin: 0 auto;
  border-radius: 100px;
  background: ${(props) => props.theme.colours.background.white};
  border: 1px solid rgba(0, 0, 76, 0.15);

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    width: 640px;
    height: 64px;
  }
`;

const MessageInput = styled(InputField)`
  margin-bottom: 0;
  width: 100%;
  input {
    color: ${(props) => props.theme.colours.text.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 110%;
    height: 20px;
    padding: 0;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      font-size: 20px;
      height: 28px;
    }
  }
`;

const SendButton = styled(ButtonIcon)`
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  &:hover {
    background: none;
    border: 0;
  }
  &:active {
    background: none;
    border: 0;
  }
`;

const Ratings = styled(RatingsBlock)`
  display: flex;
  flex-direction: row;
  max-width: 400px;
  width: 100%;
  flex-wrap: nowrap;
  margin: 15px auto 50px;

  @media only screen and (max-width: 359px) {
    flex-direction: column;
    div {
      &:first-child {
        margin-bottom: 10px;
        padding-right: 0;
      }
    }
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    width: 400px;
    flex-direction: row;
    div {
      &:first-child {
        margin-bottom: 0;
      }
      width: 175px;
    }
  }
`;

const Trash = styled.img`
  width: 39px;
  height: 38px;
  cursor: pointer;
  margin-left: 8px;
  opacity: ${(props) => (props.$waitingResponse ? 0.2 : 1)};
`;

const Heading = styled(Headline)`
  z-index: 1;
  font-size: 35px;
  line-height: 40px;
  padding-bottom: 10px;
  margin-bottom: 0;
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin-bottom: 15px !important;
    padding-bottom: 5px;
  }
`;

const DotPulse = styled.div`
  position: relative;
  left: -10010px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: transparent;
  color: ${(props) => props.theme.colours.background.navy};
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: transparent;
    color: ${(props) => props.theme.colours.background.navy};
  }
  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
    left: 2px;
  }
  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
    right: -2px;
  }
  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px;
    }
  }
`;

const ContinueWrap = styled(Container)`
  background-color: ${(props) => props.theme.colours.background.nude};
  .container-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 640px;
    padding-top: 0;
    padding-bottom: 50px;
  }
`;

const LeftArrow = styled.i`
  margin-right: 10px;
  width: 5px;
  height: 10px;
  display: inline-block;
  background-image: url(${ArrowDarkLeft});
  background-repeat: no-repeat;
`;

const RightArrow = styled.i`
  margin-left: 10px;
  width: 7px;
  height: 11px;
  display: inline-block;
  background-image: url(${ArrowLightRight});
  background-repeat: no-repeat;
`;

const StartAgainButton = styled(ButtonText)`
  position: relative;
  z-index: 1;
`;

const Examples = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  padding: 7px 0;
  button {
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    padding: 6px 12px;
    background: ${(props) => props.theme.colours.background.creamFade};
    margin: 8px 0;
    cursor: pointer;
  }
  button:nth-child(1),
  button:nth-child(2),
  button:nth-child(3),
  button:nth-child(4) {
    margin-right: 8px;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    padding: 16px 0;
    justify-content: center;
    button {
      font-size: 15px;
    }
    button:nth-child(1),
    button:nth-child(2),
    button:nth-child(4) {
      margin-right: 22px;
    }
  }
`;

const LottieWrap = styled.div`
  opacity: ${(props) => (props.$animationComplete ? 0 : 1)};
  width: ${(props) => (props.$animationComplete ? 0 : 'auto')};
  height: ${(props) => (props.$animationComplete ? 0 : 'auto')};
  transition: all 0.5s ease-in-out;
  div {
    margin: 0 !important;
  }
`;

const StartButtonSection = styled(ChatContainer)`
  .container-inner {
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    a[role='button'] {
      margin-right: 51px;
      z-index: 55;
    }

    ${Ratings} {
      margin: 15px 0;
      z-index: 15;
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
      a[role='button'] {
        position: relative;
        top: 7px;
        left: 0;
        margin-right: 20px;
      }

      ${Ratings} {
        display: flex;
        flex-direction: column;
      }

      img {
        height: 30px;
      }

      img[alt='Star'] {
        width: 15px !important;
        height: 15px !important;
      }

      span {
        font-size: 12px;
      }
    }
  }
`;

const ModalHeader = styled(H3)``;

const ModalButtons = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  button {
    margin-left: 15px;
    width: 100px;
    min-width: 100px;
  }
`;

// Should probably have built in a streaming queue, rather than all these checks everywhere.
// This got a lot bigger than planned, need to refactor if we are keeping this design.

const ChatHeader = ({ onHeaderFixed, heading, subHeading }) => {
  // const [summary, setSummary] = useState(null);
  const [chatHistory, setChatHistory] = useState(null);
  const [chatting, setChatting] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(true);
  const [intro, setIntro] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [canShowQuestion, setCanShowQuestion] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [pitchResponse, setPitchResponse] = useState(null);
  const [doneSummary, setDoneSummary] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [newItemAdded, setNewItemAdded] = useState(0);
  const [nextResponse, setNextResponse] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showThinking, setShowThinking] = useState(true);
  const [restartQuote, setRestartQuote] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(true);
  const [animationFaded, setAnimationFaded] = useState(false);
  // const [quoteResponse, setQuoteResponse] = useState(null);
  const [questionsCompleted, setQuestionsCompleted] = useState(false);
  const [isFooterFixed, setIsFooterFixed] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [showStartAgainModal, setShowStartAgainModal] = useState(false);
  const [thinkingMessages, setThinkingMessages] = useState();
  const prevScrollY = useRef(0);

  // const [ws, setWS] = useState(null);
  const messageForm = useRef(null);
  const historyRef = useRef(null);
  const messageRef = useRef(null);
  const headerHeight = 95;
  // const historyBottomRef = useRef(null);
  // const historyContainerRef = useRef(null);
  const requestTimeout = 120000;
  const storeName = 'chat_session';
  const chatHeaders = {
    'Content-Type': 'application/json'
  };
  const modalCSS = {
    padding: '30px 20px',
    maxWidth: '358px',
    maxHeight: '200px',
    borderRadius: '20px'
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatting && window && document) {
        if (window.innerWidth < 787) {
          const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
          const content = document.getElementById('historyWrap');

          if (scrollPosition > headerHeight && scrollPosition < prevScrollY.current) {
            onHeaderFixed(true);
            setIsHeaderFixed(true);
          } else {
            onHeaderFixed(false);
            setIsHeaderFixed(false);
          }

          prevScrollY.current = scrollPosition;

          if (scrollPosition + window.innerHeight < content.offsetHeight) {
            setIsFooterFixed(true);
          } else {
            setIsFooterFixed(false);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [chatting]);

  useEffect(() => {
    setTimeout(() => {
      setAnimationComplete(true);
      setTimeout(() => {
        setAnimationFaded(true);
      }, 500);
    }, 1); // 3500);
  }, []);

  useEffect(() => {
    if (chatId === null && animationComplete) {
      setLeadId(null);
      setAnswers(null);
      const data = {};
      const storeVal = getLocalStorage(storeName);
      if (storeVal !== null) {
        try {
          data.c_id = JSON.parse(storeVal)?.c_id;
        } catch (e) {
          console.info("Couldn't retrieve chat session, start new one", e);
        }
      }

      // post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/delay`, {}, chatHeaders, requestTimeout);

      post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/start`, data, chatHeaders, requestTimeout)
        .then((res) => {
          push('chatStart', {
            chatId: res.data.c_id
          });
          setLocalStorage(
            storeName,
            JSON.stringify({
              c_id: res.data.c_id
            })
          );
          // TODO: Add checks here incase fields are missing
          setChatId(res.data?.c_id);
          setThinkingMessages({
            first: res.data?.thinking_one,
            second: res.data?.thinking_two
          });
          addMessageToHistory('ai', res.data?.message?.content, res.data.m_id, {}, false);
          setTimeout(() => {
            setIntro(res.data?.message?.content);
          }, 1500);
          // addMessageToHistory(res.data?.message.type, res.data?.message.content);
          setWaitingResponse(false);
        })
        .catch((err) => {
          console.info('Chat Start Error', err);
          setWaitingResponse(false);
        });
    }
  }, []); // [animationComplete]);

  useEffect(() => {
    const container = historyRef.current;
    const messageContainer = messageRef.current;

    if (container && messageContainer) {
      // if (window.scrollY + window.innerHeight < container.offsetTop) {
      // container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
      const messageHeight = messageContainer.children[0].getBoundingClientRect().height;
      const windowHeight = window.innerHeight - messageHeight;
      const historyHeight = container.getBoundingClientRect().height + headerHeight;
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const newPosition = historyHeight - windowHeight;
      if (scrollPosition < newPosition) {
        window.scrollTo({
          top: newPosition,
          behavior: 'smooth'
        });
      }
      // }
    }
  }, [newItemAdded]);

  useEffect(() => {
    if (restartQuote) {
      getNextQuestion();
      setRestartQuote(false);
    }
  }, [restartQuote]);

  const getSessionData = () => {
    const data = {
      answered: answers,
      c_id: chatId,
      l_id: leadId,
      message_history: chatHistory
    };

    return data;
  };

  const itemsAdded = () => {
    setNewItemAdded(new Date().getTime());
  };

  const addMessageToHistory = (type, msg, id, data, doItemsAdded = true) => {
    const newMessage = { type, content: msg?.replace(/^\n+/, ''), id, data, time: Date.now() };
    setChatHistory((prev) => (prev ?? []).concat(newMessage));
    // scrollToBottomOfChat();
    if (doItemsAdded) {
      itemsAdded();
    }
  };

  const captureSentryMessage = (message, userInput) => {
    if (window && window.Sentry) {
      window.Sentry.setContext('message', {
        content: userInput,
        chatId,
        leadId
      });
      window.Sentry.captureMessage(message);
    }
  };

  const processError = (error) => {
    addMessageToHistory('system', error.message);
    captureSentryMessage(error.message ?? 'Server error', error.userInput);
  };

  const runPitch = () => {
    setPitchResponse(null);
    const sessionData = getSessionData();
    post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/quote/pitch`, sessionData, chatHeaders, requestTimeout)
      .then((res) => {
        // setPitchResponse(res);
        if (res.status === 200) {
          push('chatGeneratePitch', {
            chatId: sessionData.c_id,
            leadId: sessionData.leadId
          });
          setPitchResponse(res); // processResponse(res, 'pitch');
        } else if (res.status === 400) {
          console.info('Pitch not generated, missing values');
        } else console.error('Error response from pitch');
      })
      .catch((err) => {
        console.info('Pitch Error', err);
        setWaitingResponse(false);
      });
  };

  const onQuoteContinueClicked = () => {
    push('chatQuoteContinue', {
      chatId,
      leadId
    });
    document.location.href = `${process.env.GATSBY_CHAT_DOMAIN}/api/v1/quote/continue?l=${leadId}`;
  };

  const processResponse = (response, userMessage) => {
    if (!response || !response.data) {
      push('chatError', {
        chatId,
        leadId,
        error: 'No response from server',
        message: userMessage
      });
      processError({ message: 'Oops, something went wrong. Please try again...', can_continue: true, userInput: userMessage });
      setWaitingResponse(false);
      return;
    }

    if (response.data.l_id) setLeadId(response.data.l_id);

    if (response.data.error) {
      push('chatError', {
        chatId,
        leadId,
        error: response.data.error.message,
        message: userMessage
      });

      processError({ ...response.data.error, userInput: userMessage });
    }

    if (response.data.message) {
      if (response.data.quote && !questionsCompleted) {
        // ignore, the answers must have changed
      } else {
        let msgContent = response.data.message.content;
        let showSummary = false;
        let quoteBottom = null;
        let summaryBottom = null;

        if (response.data.questions_completed && response.data.input_summary) {
          if (doneSummary) msgContent = 'Thanks! Does this all look ok now?{{summary}}If you need to change anything, just hit the X.';

          [msgContent, summaryBottom] = msgContent.split('{{summary}}');

          setQuestionsCompleted(true);
          setDoneSummary(true);
          push('chatQuoteSummary', {
            chatId,
            leadId
          });
          showSummary = true;
        } else if (response.data.quote) {
          if (response.data.quote?.generated) {
            if (msgContent.includes('{{quote_results}}')) {
              [msgContent, quoteBottom] = msgContent.split('{{quote_results}}');
            }
          }
        }

        addMessageToHistory(
          response.data.message.type,
          msgContent,
          response.data.m_id,
          {
            lead_id: response.data.l_id,
            msgType: null
          },
          true
        );

        if (quoteBottom) {
          addMessageToHistory('ai', quoteBottom, null, { quote: response.data.quote, msgType: 'quote', lead_id: response.data.l_id }, true);
        }
        if (showSummary) {
          addMessageToHistory('ai', summaryBottom ?? ' ', null, { summary: response.data.input_summary, msgType: 'summary' }, true);
        }
      }
    }

    if (response.data.question) {
      if (!answers) setAnswers({}); // make it so we stay in quote mode even if they dont answer any questions
      if (response.data.question.field_name === 'start' && !response.data.message) {
        setCanShowQuestion(true); // only do this when they first ask for a quote, not when a response is received to user input
        push('chatStartQuoteMode', {
          chatId
        });
      }
      setCurrentQuestion(response.data.question);
    }
    // just finished last question

    setWaitingResponse(false);

    if (!chatHistory) {
      updateCurrentIndex(1);
    } else if (currentIndex === chatHistory?.length - 1) {
      updateCurrentIndex();
    }
  };

  const sendMessage = (msg) => {
    const data = {
      message: {
        type: 'human',
        content: msg
      },
      data: getSessionData()
    };
    setWaitingResponse(true);
    push('chatSendMessage', {
      chatId,
      leadId,
      message: msg
    });
    post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/check_input`, { ...data, data: { ...data.data, message_history: undefined } }, chatHeaders, requestTimeout)
      .then((res) => {
        const isQuestion = res.data.is_question;
        setShowThinking(isQuestion);
        post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/message`, data, chatHeaders, requestTimeout)
          .then((messageRes) => {
            processResponse(messageRes, msg);
          })
          .catch((err) => {
            console.info('Chat message Error', err);
            processResponse(undefined, msg);
          });
      })
      .catch((err) => {
        console.info('Chat message Error', err);
        processResponse(undefined, msg);
      });
  };

  const runQuote = () => {
    setWaitingResponse(true);
    if (pitchResponse) {
      processResponse(pitchResponse);
    }
    push('chatRunQuote', {
      chatId,
      leadId
    });
    post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/quote/run`, getSessionData(), chatHeaders, requestTimeout)
      .then((res) => {
        push('chatQuoteCompleted', {
          chatId,
          leadId: res.data.l_id,
          quoted: res.data?.quote?.generated
        });
        runSummary(res.data.l_id);
        if (!streaming) {
          setTimeout(() => {
            processResponse(res);
          }, 500);
        } else {
          setNextResponse(res);
        }
      })
      .catch((err) => {
        console.info('Quote Error', err);
        setWaitingResponse(false);
      });
  };

  const runSummary = (lId) => {
    const dataWithNewLeadId = { ...getSessionData(), l_id: lId };
    push('chatRunSummary', {
      chatId,
      leadId: lId
    });
    post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/summarize`, dataWithNewLeadId, chatHeaders, requestTimeout)
      .then(() => {})
      .catch((err) => {
        console.info('Run Summary Error', err);
      });
  };

  const getNextQuestion = (questionId, answerValue) => {
    setWaitingResponse(true);
    setCurrentQuestion(null);
    setShowContinue(false);
    push('chatNextQuestion', {
      chatId,
      leadId
    });
    post(
      `${process.env.GATSBY_CHAT_DOMAIN}/api/v1/quote/next_question`,
      { ...getSessionData(), currentQuestionId: questionId, currentAnswer: answerValue },
      chatHeaders,
      requestTimeout
    )
      .then((res) => {
        if (res.data.message) {
          processResponse(res);
          itemsAdded();
        } else if (res.data.question !== null) {
          setCanShowQuestion(true);
          setCurrentQuestion(res.data.question);
          itemsAdded();
        }
        setWaitingResponse(false);
      })
      .catch((err) => {
        console.info('Next Question Error', err);
        setWaitingResponse(false);
      });
  };

  const onAnswerEntered = (question, answer, runNextQuestion = true) => {
    setQuestionsCompleted(false);
    setAnswers(() => {
      (answers ?? {})[question.field_name] = answer.val;
      return answers;
    });

    if (runNextQuestion) {
      setCanShowQuestion(false);
      addMessageToHistory('system', question.question, null, { msgType: 'question', question_id: question.field_name });
      let humanVal = answer.val;
      if (question.field_type === 'currency') humanVal = `$${answer.val}`;

      if (question.field_type === 'dropdown' || question.field_type === 'button') {
        // const stateName = constants.STATES.find((state) => state.val.toUpperCase() === (val ?? '').toUpperCase());
        humanVal = answer.text; // stateName.text ?? val;
      }
      addMessageToHistory('human', humanVal, null, { msgType: 'answer', question_id: question.field_name });

      setCurrentQuestion(null);
      setTimeout(() => {
        getNextQuestion(question.field_name, answer.val);
      }, 200);

      // const isRefi = answers.application_type && answers.application_type.toLowerCase().indexOf('refi') > -1;
      if (['property_type', 'application_type', 'occupancy', 'property_city', 'property_state'].includes(question.field_name)) {
        setPitchResponse(null);
        runPitch();
      }
      updateCurrentIndex(currentIndex + 2);
    }
  };

  // Send message to server
  const sendClick = (event) => {
    event.preventDefault();
    if (waitingResponse) return;

    const msg = messageForm.current.messageText.value;
    if (msg === '') {
      return;
    }

    const questionNotInHistory = chatHistory && chatHistory.every((m) => !(m.data?.msgType === 'question_answer' && m.data?.question.field_name === currentQuestion.field_name));

    // console.info('sendClick', currentQuestion);
    // console.info('chatHistory', chatHistory);
    // console.info('questionNotInHistory', questionNotInHistory);
    let newCurrentIndex = currentIndex;
    if (currentQuestion && questionNotInHistory) {
      // console.info('adding quote question to disabled history');
      newCurrentIndex += 1;
      addMessageToHistory('system', null, null, { msgType: 'question_answer', question: currentQuestion });
    }

    addMessageToHistory('human', msg);
    newCurrentIndex += 1;
    updateCurrentIndex(newCurrentIndex);
    setCanShowQuestion(false);
    sendMessage(msg);
    if (!chatting && window) {
      window.scrollTo(0, 0, { behavior: 'smooth' });
    }
    setChatting(true);

    event.target.reset();
    messageForm.current.messageText.blur();
    setWaitingResponse(true);
  };

  const onSummaryContinueClicked = () => {
    setWaitingResponse(true);
    runQuote();
  };

  const onMessageEditClick = (questionId) => {
    setShowContinue(false);
    setAnswers(() => {
      (answers ?? {})[questionId] = undefined;
      return answers;
    });
    setTimeout(() => {
      getNextQuestion(questionId);
    }, 200);
  };

  const getAddressType = () => {
    if (answers) {
      const isRefi = answers.application_type && answers.application_type.toLowerCase().indexOf('refi') > -1;
      if (isRefi) return 'address';
      if (answers.signed_purchase_agreement && answers.signed_purchase_agreement.toLowerCase() === 'yes') return 'address';
    }
    return '(regions)';
  };

  const onStartAgain = (full = false) => {
    // Reset everything
    push('chatQuoteModeReset', {
      chatId
    });
    setShowContinue(false);
    setCurrentQuestion(null);
    setCanShowQuestion(true);
    setDoneSummary(false);
    setQuestionsCompleted(false);
    setPitchResponse(null);
    setNextResponse(null);
    setShowThinking(true);
    if (full) {
      setWaitingResponse(false);
      setChatHistory(null);
      setChatting(false);
      // setCurrentIndex(0);
      updateCurrentIndex(0);
      setNewItemAdded(0);
      setAnswers(null);
      // setIntro(null);
      if (window) window.scrollTo(0, 0, { behavior: 'smooth' });
    } else {
      setAnswers({ start: 'yes' });
      setRestartQuote(true);
    }
  };

  const trashClick = () => {
    setShowStartAgainModal(true);
  };

  const onStartAgainModalClose = () => {
    setShowStartAgainModal(false);
  };

  const onStartAgainModalDelete = () => {
    onStartAgain(true);
    onStartAgainModalClose();
  };

  const onStream = () => {
    setStreaming(true);
    itemsAdded();
  };

  const onQuestionStreamingCompleted = () => {
    setStreaming(false);
    itemsAdded();
  };

  const updateCurrentIndex = (value) => {
    if (value) {
      setCurrentIndex(value);
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const onMessageStreamingCompleted = (msgType) => {
    setCanShowQuestion(true);
    setStreaming(false);

    if (msgType === 'quote' || msgType === 'summary') {
      if (window) {
        setTimeout(() => {
          window.scrollTo(0, window.scrollY + 100, { behavior: 'smooth' });
        }, 1500);
      }
    }

    if (msgType === 'quote') {
      setShowContinue(true);
    }

    updateCurrentIndex();
    if (nextResponse) {
      setTimeout(() => {
        processResponse(nextResponse);
        setNextResponse(null);
      }, 1500);
    }
  };

  const loadExample = (example) => {
    if (messageForm && messageForm.current) {
      messageForm.current.messageText.value = example;
      messageForm.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  // always keep the most recent item in the streaming history component
  const historyLastItem = currentIndex < chatHistory?.length - 1 ? currentIndex : chatHistory?.length - 1;
  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: logoAnimationData,
    eventListeners: {
      complete: () => {
        // this doesnt seem to fire
        console.info('completed');
      }
    },
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      hideOnTransparent: true
    }
  };

  const onThumbClicked = (id, like) => {
    const data = {
      c_id: chatId,
      m_id: id,
      like
    };
    post(`${process.env.GATSBY_CHAT_DOMAIN}/api/v1/chat/like`, data, chatHeaders, requestTimeout)
      .then(() => {
        push('chatLike', {
          chatId,
          messageId: id,
          like
        });
      })
      .catch((err) => {
        console.error(`Chat - Message like failed ${id}`, err);
      });
  };

  return (
    <ChatWrap $chatting={chatting} $headerFixed={isHeaderFixed}>
      <HeadlineContainer $hide={chatting}>
        <Heading>{heading}</Heading>
        <SubHeading textAlign="center">{subHeading}</SubHeading>
      </HeadlineContainer>
      <StartButtonSection $hide={chatting}>
        <HeroButton to={applyEndpoint}>Start</HeroButton>
        <Ratings v2 googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM} bbbRating={process.env.GATSBY_BBB_REVIEW_NUM} />
      </StartButtonSection>
      <MessageItem type="ai" showThumbs={false} hide={chatting} backgroundColor={Theme.colours.background.cream}>
        {!animationComplete && (
          <LottieWrap $animationComplete={animationComplete}>
            <Lottie options={lottieOptions} height={30} width={97} />
          </LottieWrap>
        )}
        {!intro && animationComplete && animationFaded && <LoadingCursor />}
        {intro && animationComplete && (
          <span>
            <TextStreaming txtToStream={intro} />
          </span>
        )}
      </MessageItem>
      <HistoryWrap id="historyWrap" $hide={!chatting} $headerFixed={isHeaderFixed} ref={historyRef}>
        <ChatHistory
          messageHistory={(chatHistory ?? []).slice(0, historyLastItem)}
          length={historyLastItem + 1}
          waitingResponse={false}
          summaryContinueClicked={onSummaryContinueClicked}
          onEditClick={onMessageEditClick}
          allowStream={false}
          onThumbClick={onThumbClicked}
          thinkingMessages={thinkingMessages}
        />
        {/* <hr /> */}
        <ChatHistory
          messageHistory={(chatHistory ?? []).slice(historyLastItem, historyLastItem + 1)}
          length={1}
          waitingResponse={waitingResponse}
          summaryContinueClicked={onSummaryContinueClicked}
          onEditClick={onMessageEditClick}
          onStreamingCompleted={onMessageStreamingCompleted}
          onStream={onStream}
          showThinking={showThinking}
          allowStream
          onThumbClick={onThumbClicked}
          thinkingMessages={thinkingMessages}
        />
        {showContinue && (
          <ContinueWrap>
            <StartAgainButton
              onClick={() => {
                onStartAgain(false);
              }}
            >
              <LeftArrow />
              Start again
            </StartAgainButton>
            <Button onClick={onQuoteContinueClicked}>
              Continue
              <RightArrow />
            </Button>
          </ContinueWrap>
        )}
        {currentQuestion && canShowQuestion && (
          <ChatQuoteQuestion question={currentQuestion} onAnswerEntered={onAnswerEntered} addressType={getAddressType()} onStreamingCompleted={onQuestionStreamingCompleted} />
        )}
      </HistoryWrap>
      <div ref={messageRef}>
        <MessageContainer id="messageWrap" $chatting={chatting} $fixed={isFooterFixed}>
          <form onSubmit={sendClick} ref={messageForm}>
            <MessageBox $waitingResponse={waitingResponse}>
              <MessageInput id="messageText" placeholder="Send a message" border={false} data-hj-allow />
              {waitingResponse && <DotPulse />}
              {!waitingResponse && <SendButton icon={PaperPlaneImg} typeAttribute="submit" disabled={waitingResponse} />}
            </MessageBox>
            <Trash src={TrashImg} alt="Clear message" onClick={trashClick} $waitingResponse={waitingResponse} />
          </form>
          {!chatting && (
            <Examples>
              {['Do you do DSCR loans?', 'Can I get a quote?', 'Why choose Beeline?', 'Can I get cash out?', 'What types of loans do you do?'].map((example) => (
                <button
                  disabled={waitingResponse}
                  type="button"
                  key={example}
                  onClick={() => {
                    loadExample(example);
                  }}
                >
                  {example}
                </button>
              ))}
            </Examples>
          )}
          <AILegal>Information provided by Bob is not intended as financial advice, may be inaccurate and does not constitute a credit decision or commitment to lend.</AILegal>
        </MessageContainer>
      </div>
      <Plant src={PlantImg} alt="Plant" />
      <Modal open={showStartAgainModal} hideCloseButton onClose={onStartAgainModalClose} jsCSS={modalCSS}>
        <ModalHeader>Are you sure you want to clear the conversation?</ModalHeader>
        <ModalButtons>
          <ButtonOutline onClick={onStartAgainModalClose}>Cancel</ButtonOutline>
          <Button onClick={onStartAgainModalDelete}>Delete</Button>
        </ModalButtons>
      </Modal>
    </ChatWrap>
  );
};

ChatHeader.propTypes = {
  onHeaderFixed: PropTypes.func,
  heading: PropTypes.node,
  subHeading: PropTypes.node
};

ChatHeader.defaultProps = {
  onHeaderFixed: () => {},
  heading: (
    <Breakpoint
      smallBreakpoint={<>Loans to help anyone build a real estate empire.</>}
      largeBreakpoint={
        <>
          Loans to help anyone build
          <br />a real estate empire.
        </>
      }
    />
  ),
  subHeading: (
    <Breakpoint
      smallBreakpoint={<>Skip the painful application. Loans for all situations — simple or kooky. Close fast, then you&apos;ll have it made.</>}
      largeBreakpoint={
        <>
          Skip the painful application.
          <br />
          Loans for all situations — simple or kooky.
          <br />
          Close fast, then you&apos;ll have it made.
        </>
      }
    />
  )
};

export default ChatHeader;
