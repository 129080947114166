import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextStreaming from './TextStreaming';
import LoadingCursor from './LoadingCursor';

const ThinkingItem = ({ stop, firstMessages, secondMessages }) => {
  const [firstRandomItem, setFirstRandomItem] = useState(null);
  const [secondRandomItem, setSecondRandomItem] = useState(null);
  // const [thirdRandomItem, setThirdRandomItem] = useState(null);

  if (!firstMessages || !secondMessages) {
    console.error('ThinkingItem requires firstMessages and secondMessages props');
  }

  const first = [
    'Hmm, just give me a moment...',
    'Hmmm...',
    "You're really keeping me on my toes, aren't you? ...",
    "Hey, you're making me think on my feet here! ...",
    'One moment...',
    'One sec...',
    'Let me check for you... ',
    'Let me see...',
    'Let me find out...',
    'Let me get the answer to that for you...',
    'Let me get the exact answer for you...',
    'Let me just confirm exactly...',
    'Give me a sec...',
    'Let me check on that more precisely for you...',
    'Hold on, let me get a good answer for you...',
    "Let's see...",
    'Let me check...',
    'Give me a moment...',
    'Ahh... one sec...',
    'Allow me a moment...',
    'OK... one moment...',
    'Just a sec...',
    'Hold up... let me see...',
    'Let me just grab that info that for you...',
    'Bear with me...',
    'Let me take a second or two...',
    'Give me a sec...',
    'Just a moment while I consult the oracle. ;)',
    "Hold on, I'm going to do some double checking...",
    "The answer's on the tip of my tongue...",
    'I think I know the answer, let me just confirm it...',
    'Hold on a sec...',
    'Hmm, hang tight...',
    'Hang tight... ',
    "Hold that thought, I'll get you a good answer...",
    "I'll just be one moment...",
    'Let me think about that...',
    'Uh huh... hmmm...',
    'Yep, Ok... thinking...',
    'Just a second...'
  ];

  const second = [
    'sorry... nearly there',
    'almost there...',
    'nearly done...',
    'just about there...',
    'so close...',
    'there we are...',
    'almost ready...',
    'nearly there...',
    'here we go...',
    'so close...',
    'last step...',
    'incoming...',
    'sorry... just need another sec...',
    'one moment more...',
    'finalizing...',
    'any second...',
    'hang tight, nearly there...',
    'just a little longer...',
    'hang in there, nearly there...'
  ];

  // const third = [
  //   'Sorry... nearly there',
  //   'Almost there...',
  //   'Nearly done...',
  //   'Just about there...',
  //   'So close...',
  //   'There we are...',
  //   'Almost ready...',
  //   'Nearly there...',
  //   'Here we go...',
  //   'So close...',
  //   'Last step...',
  //   'Incoming...',
  //   'Sorry... just need another sec.',
  //   'Finishing up...',
  //   'One moment more...',
  //   'Finalizing...',
  //   'Any second...',
  //   'Hang tight, nearly done...',
  //   'Just a little longer...',
  //   "Hang in there, it's coming..."
  // ];

  useEffect(() => {
    const selectRandomItem = (array, setter) => {
      const randomString = array[Math.floor(Math.random() * array.length)];
      setter(`${randomString} `);
    };

    const firstArrayTimer = setTimeout(() => {
      if (!stop) {
        selectRandomItem(firstMessages ?? first, setFirstRandomItem);
      }
    }, 5000);

    const secondArrayTimer = setTimeout(() => {
      if (!stop) {
        selectRandomItem(secondMessages ?? second, setSecondRandomItem);
      }
    }, 10000);

    // const thirdArrayTimer = setTimeout(() => {
    //   if (!stop) {
    //     selectRandomItem(third, setThirdRandomItem);
    //   }
    // }, 15000);

    return () => {
      clearTimeout(firstArrayTimer);
      clearTimeout(secondArrayTimer);
      // clearTimeout(thirdArrayTimer);
    };
  }, []);

  return (
    <>
      {!firstRandomItem && <LoadingCursor />}
      <TextStreaming txtToStream={firstRandomItem} keepBlinking={!secondRandomItem} />
      <TextStreaming txtToStream={secondRandomItem} keepBlinking />
      {/* <TextStreaming txtToStream={thirdRandomItem} keepBlinking /> */}
    </>
  );
};

ThinkingItem.propTypes = {
  stop: PropTypes.bool.isRequired,
  firstMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  secondMessages: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ThinkingItem;
