import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// import { Button } from '@beelineloans/cx-library/src/components/buttons';
import { Paragraph, Span } from '@beelineloans/cx-library/src/components/typography';
import { MessageItem } from './ChatMessage';
import TextStreaming from './TextStreaming';
// import CloseCircle from '../../../../shared/images/close-circle.svg';

const QuoteWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => props.theme.colours.background.cream};
  border-radius: 10px;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ${(props) =>
    props.$show &&
    css`
      opacity: 1;
      height: 100% !important;
      overflow: auto;
    `}
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: 1px solid ${(props) => props.theme.colours.border.light};
  &:last-of-type {
    border-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom 8px;
`;

const ProgramRow = styled(Row)`
  margin-top: -15px;
`;

const Title = styled(Span)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${(props) => (props.$fade ? props.theme.colours.text.darkFade : props.theme.colours.text.dark)};
`;

const Rate = styled(Span)`
  font-weight: 500;
  font-size: ${(props) => (props.$large ? '35px' : '24px')};
  line-height: ${(props) => (props.$large ? '35px' : '24px')};
  color: ${(props) => (props.$fade ? props.theme.colours.text.darkFade : props.theme.colours.text.dark)};

  span {
    position: relative;
    top: ${(props) => (props.$suffixPosition === 'top' ? '-10' : '0')}px;
    font-size: ${(props) => (props.$large ? '24px' : '15px')};
    margin-left: 3px;
  }
`;
const Amount = styled(Span)`
  font-weight: 500;
  font-size: 24px;

  span: first-of-type {
    position: relative;
    top: -7px;
    font-size: 12px;
    margin-right: 3px;
  }

  span: last-of-type {
    position: relative;
    bottom: 0;
    font-size: 18px;
    margin-left: 3px;
  }
`;

const ClosingCosts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  span: first-of-type {
    padding-bottom: 3px;
  }
`;

const ProgramDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-top: 8px;
`;

const Bubble = styled(Span)`
  font-weight: 500;
  font-size: 8px;
  color: ${(props) => props.theme.colours.text.light};
  background: ${(props) => props.theme.colours.background.navy};
  border-radius: 10px;
  padding: 3px 10px;
  text-transform: uppercase;
  line-height: 8px;
  margin: 0 4px;
`;

const Disclaimer = styled.div`
  background-color: #f2cfc7;
  padding: 15px;
  p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
  }
`;

const QuoteMessageItem = styled(MessageItem)`
  .container-inner {
    padding: 0;
    .message_body {
      margin: 0 10px;
    }
  }
`;

const ChatQuoteItem = ({ item, title }) => {
  if (!item) return null;

  const isValueNegative = (val) => val.includes('-');

  return (
    <ItemGroup>
      <Row>
        <Title>{title}</Title>
      </Row>
      <Row>
        <Rate $large $suffixPosition="top">
          {item.rate}
          <span>%</span>
        </Rate>
        <Amount>
          <span>$</span>
          {item.monthlyPayment.replace('$', '')}
          <span>/ mth</span>
        </Amount>
      </Row>
      <Row>
        <Rate $fade $suffixPosition="bottom">
          {item.apr}%<span>APR</span>
        </Rate>
        <ClosingCosts>
          <Title $fade>Est. Closing Cost</Title>
          <Amount>
            <span>$</span>
            {isValueNegative(item.closingCosts) ? `(${item.closingCosts.replace('$', '')})` : item.closingCosts.replace('$', '')}
          </Amount>
        </ClosingCosts>
      </Row>
      <ProgramRow>
        <ProgramDetails>
          <Title $fade>30 YR</Title>
          <Bubble>Fixed</Bubble>
          <Bubble>Conventional</Bubble>
        </ProgramDetails>
      </ProgramRow>
    </ItemGroup>
  );
};

ChatQuoteItem.propTypes = {
  item: PropTypes.shape({
    monthlyPayment: PropTypes.string.isRequired,
    closingCosts: PropTypes.string.isRequired,
    rate: PropTypes.string.isRequired,
    apr: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired
};

const ChatQuote = ({ msg, quote, isMostRecent, onStreamingCompleted, allowStream }) => {
  if (!quote) return null;
  const [show, setShow] = useState(allowStream ? false : !isMostRecent);

  useEffect(() => {
    if (isMostRecent) {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  return (
    <>
      <QuoteMessageItem type="ai" showThumbs={false} showIcon={false}>
        <QuoteWrap $show={show || !isMostRecent}>
          <ChatQuoteItem title="Balanced" item={quote.balanced} />
          <ChatQuoteItem title="Lowest Monthly" item={quote.lowest_monthly} />
          <ChatQuoteItem title="Lowest Upfront" item={quote.lowest_upfront} />
          <Disclaimer>
            <Paragraph>
              <b>Other closing costs</b>
              <br />
              There’s a small origination fee of $999 — and please remember you’re also responsible for your appraisal (if one is needed) and other closing costs such as insurance,
              title, taxes etc. How much they are depends on your property.
            </Paragraph>
          </Disclaimer>
        </QuoteWrap>
      </QuoteMessageItem>
      {show && (
        <MessageItem type="ai" showThumbs={false}>
          {isMostRecent && allowStream && (
            <TextStreaming
              txtToStream={msg.content}
              onStreamingCompleted={() => {
                onStreamingCompleted(msg.data?.msgType);
              }}
            />
          )}
          {(!isMostRecent || !allowStream) && <span>{msg.content}</span>}
        </MessageItem>
      )}
    </>
  );
};

ChatQuote.propTypes = {
  msg: PropTypes.shape().isRequired,
  quote: PropTypes.shape({
    balanced: PropTypes.shape(),
    lowest_upfront: PropTypes.shape(),
    lowest_monthly: PropTypes.shape()
  }).isRequired,
  isMostRecent: PropTypes.bool,
  onStreamingCompleted: PropTypes.func,
  allowStream: PropTypes.bool
};

ChatQuote.defaultProps = {
  isMostRecent: false,
  onStreamingCompleted: () => {},
  allowStream: false
};

export default ChatQuote;
