import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import ThumbUpImg from '../../../../shared/images/thumbs-up.svg';
import ThumbUpDarkImg from '../../../../shared/images/thumbs-up-dark.svg';
import AIIcon from '../../../../shared/images/ai-icon.svg';
import UserIcon from '../../../../shared/images/user-icon.svg';
import PenIcon from '../../../../shared/images/edit-pen.svg';

const Message = styled(Container)`
  .container-inner {
    padding: 35px 15px;
    box-sizing: border-box;
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    z-index: 20;
    position: relative;
    a {
      color: ${(props) => props.theme.colours.text.dark} !important;
      text-decoration: underline !important;
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const MessageText = styled.div`
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin: 0 20px;
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const UserMessage = styled(Message)``;

const AIMessage = styled(Message)`
  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `}

  .container-inner {
    flex-wrap: wrap;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding-left: 0;
      padding-right: 0;
      flex-wrap: nowrap;
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
      img:first-child {
        flex: 1;
      }
      div:nth-child(2):not(.message_body div) {
        flex: 50;
      }
      div:nth-child(3):not(.message_body div) {
        flex-basis: 100%;
        align-self: flex-end;
        img {
          flex: 0;
        }
      }

      ${MessageText} {
        margin: 0 0 0 20px;
      }
    }
  }
`;

const Thumb = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ThumbUp = styled(Thumb)``;
const ThumbDown = styled(Thumb)`
  rotate: 180deg;
`;

const Thumbs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 53px;
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    align-self: end;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  position: relative;
  top: 0;
`;

const EditIcon = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  top: -5px;
  cursor: pointer;
`;

const Blank = styled.div`
  width: 53px;
`;

const MessageItem = ({ className, type, showThumbs, showEdit, children, hide, onThumbClick, onEditClick, showIcon, includeBlank, ...rest }) => {
  const ParentComponent = type === 'ai' ? AIMessage : UserMessage;
  const [thumb, setThumb] = useState(undefined);
  const [edited, setEdited] = useState(false);

  const onUpClick = () => {
    let newValue;
    if (thumb) newValue = undefined;
    else newValue = true;

    // setThumb((prev) => (prev === true ? null : true));
    setThumb(newValue);
    onThumbClick(newValue);
  };

  const onDownClick = () => {
    let newValue;
    if (thumb === false) newValue = undefined;
    else newValue = false;

    // setThumb((prev) => (prev === false ? null : false));
    setThumb(newValue);
    onThumbClick(newValue);
  };

  const onEditIConClick = () => {
    setEdited(true);
    onEditClick();
  };
  return (
    <ParentComponent $hide={hide} className={className} {...rest}>
      {showIcon && <Icon src={type === 'ai' ? AIIcon : UserIcon} />}
      <MessageText className="message_body">{children}</MessageText>
      {showThumbs && !showEdit && (
        <Thumbs>
          <ThumbUp src={thumb ? ThumbUpDarkImg : ThumbUpImg} onClick={onUpClick} />
          <ThumbDown src={thumb === false ? ThumbUpDarkImg : ThumbUpImg} onClick={onDownClick} />
        </Thumbs>
      )}
      {showEdit && !edited && <EditIcon src={PenIcon} onClick={onEditIConClick} />}
      {!showEdit && !showThumbs && showIcon && includeBlank && <Blank />}
    </ParentComponent>
  );
};

MessageItem.propTypes = {
  type: PropTypes.string.isRequired,
  showThumbs: PropTypes.bool,
  showEdit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  onThumbClick: PropTypes.func,
  onEditClick: PropTypes.func,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  includeBlank: PropTypes.bool
};

MessageItem.defaultProps = {
  showThumbs: true,
  hide: false,
  showEdit: false,
  onThumbClick: () => {},
  onEditClick: () => {},
  showIcon: true,
  includeBlank: true
};

export { UserMessage, AIMessage, MessageItem };
