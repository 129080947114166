import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import constants from '@beelineloans/cx-library/src/utils/constants';
import { MessageItem } from './ChatMessage';
import TextStreaming from './TextStreaming';
import ThinkingItem from './ThinkingItem';
import ChatHTMLItem from './ChatHTMLItem';
import LoadingCursor from './LoadingCursor';

const MessageSpan = styled.span``;

const ChatHistory = ({
  messageHistory,
  waitingResponse,
  length,
  summaryContinueClicked,
  onStreamingCompleted,
  onStream,
  onEditClick,
  allowStream,
  showThinking,
  onThumbClick,
  thinkingMessages
}) => {
  if (!messageHistory || length === 0) {
    return null;
  }

  const [streamCompleted, setStreamCompleted] = useState(false);

  const replaceURLsWithLinks = (string) => {
    // Regular expression to match URLs
    /* eslint-disable-next-line no-useless-escape */
    const urlRegex = /(( )(https?:\/\/)?[\w\-]+(\.[\w\-]+)+\.?(:\d+)?([^.,\s]*[^.,\s.,:])?(\/[\w\d]*)?)/gi;

    // Replace URLs with links
    const replacedString = string.replace(urlRegex, (url) => {
      let href = url.replace(' ', '');
      if (!href.match('^https?://')) {
        href = `http://${href}`;
      }
      return ` <a href="${href}" target="_blank">${url}</a>`;
    });

    return replacedString;
  };

  const replaceEmailsWithLinks = (string) => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /([^\s@]+@[^\s@]+\.[^\s@\.]{2,4})/gi;

    // Replace URLs with links
    const replacedString = string.replace(emailRegex, (email) => {
      const isAllowedEmail = ['commercial@makeabeeline.com', 'privacy@makeabeeline.com', 'hello@makeabeeline.com'].includes(email);
      const emailAddress = isAllowedEmail ? email : constants.LINKS.EXTERNAL.EMAIL.DEFAULT;
      return `<a href="mailto:${emailAddress}" target="_blank">${emailAddress}</a>`;
    });

    return replacedString;
  };

  const cleanMessage = (msg, data) => {
    let cleaned = msg;
    cleaned = replaceEmailsWithLinks(cleaned);
    cleaned = replaceURLsWithLinks(cleaned);
    cleaned = cleaned.replace(/Book a call/gi, '<a href="https://calendly.com/loan-guides" target="_blank">Book a call</a>');
    cleaned = cleaned.replace(/\(888\) 356-2839/g, '<a href="tel:888-356-2839">(888) 356-2839</a>');
    cleaned = cleaned.replace(/\(800\) 550-6602/g, '<a href="tel:800-550-6602">(800) 550-6602</a>');
    cleaned = cleaned.replace(/Live chat /gi, '<a href="#" onClick="event.preventDefault(); if ($crisp) { $crisp.push([\'do\', \'chat:open\']); }">Live Chat</a> ');

    if (data && data.summary) {
      return '';
    }
    cleaned = cleaned.replace(/\n/g, '<br/>');
    return cleaned;
  };

  const thumbClicked = (id, like) => {
    // like = true/false/undefined
    if (!id) return;
    onThumbClick(id, like);
  };

  return (
    <>
      {(messageHistory ?? []).map((msg, idx) => {
        const key = msg.id ?? `${msg.content}-${msg.time}`;
        if (msg.type === 'ai' || msg.type === 'system') {
          const runStreamCallback = !['quote', 'quote_bottom', 'summary', 'question_answer'].includes(msg.data?.msgType);
          const showText = !['quote', 'summary', 'question_answer'].includes(msg.data?.msgType);
          // console.info('showText', showText, runStreamCallback, msg.data?.msgType);
          const isLastItem = idx === messageHistory.length - 1;
          const showThumbForMsg = isLastItem && allowStream && streamCompleted ? true : !!msg.id;
          const hasMessage = msg.content && msg.content.trim().length > 0;

          return (
            <div key={key}>
              {showText && hasMessage && (
                <MessageItem
                  type="ai"
                  onThumbClick={(val) => {
                    thumbClicked(msg.id, val);
                  }}
                  showThumbs={showThumbForMsg}
                >
                  {(!isLastItem || !allowStream) && <MessageSpan dangerouslySetInnerHTML={{ __html: cleanMessage(msg.content, msg.data) }} />}
                  {isLastItem && allowStream && (
                    <TextStreaming
                      txtToStream={streamCompleted ? cleanMessage(msg.content, msg.data) : msg.content}
                      onStream={(c) => {
                        if (runStreamCallback) onStream(c);
                      }}
                      onStreamingCompleted={() => {
                        setStreamCompleted(true);
                        onStreamingCompleted(msg.data?.msgType);
                      }}
                    />
                  )}
                </MessageItem>
              )}
              <ChatHTMLItem
                msg={msg}
                onSummaryContinueClick={summaryContinueClicked}
                onSummaryEditClick={onEditClick}
                showButtons={isLastItem}
                allowStream={allowStream}
                onStreamingCompleted={(msgType) => {
                  onStreamingCompleted(msgType);
                  setStreamCompleted(true);
                }}
              />
            </div>
          );
        }
        if (msg.type === 'human') {
          const isAnswer = msg.data?.msgType === 'answer';

          return (
            <MessageItem
              type="human"
              showThumbs={false}
              showEdit={isAnswer && msg.data.question_id !== 'start'}
              key={key}
              onEditClick={() => {
                if (isAnswer) {
                  onEditClick(msg.data?.question_id);
                }
              }}
              backgroundColor={Theme.colours.background.cream}
            >
              <MessageSpan>{msg.content}</MessageSpan>
            </MessageItem>
          );
        }
        return null;
      })}
      {waitingResponse && (
        <MessageItem type="ai" showThumbs={false}>
          {showThinking && thinkingMessages && thinkingMessages.first && thinkingMessages.second && (
            <ThinkingItem stop={!waitingResponse} firstMessages={thinkingMessages.first} secondMessages={thinkingMessages.second} />
          )}
          {showThinking && !thinkingMessages && <LoadingCursor />}
          {!showThinking && <LoadingCursor />}
        </MessageItem>
      )}
    </>
  );
};

ChatHistory.propTypes = {
  messageHistory: PropTypes.array,
  length: PropTypes.number,
  waitingResponse: PropTypes.bool,
  showThinking: PropTypes.bool,
  summaryContinueClicked: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  onStreamingCompleted: PropTypes.func,
  onStream: PropTypes.func,
  allowStream: PropTypes.bool,
  onThumbClick: PropTypes.func,
  thinkingMessages: PropTypes.shape({
    first: PropTypes.array,
    second: PropTypes.array
  })
};

ChatHistory.defaultProps = {
  messageHistory: [],
  length: 0,
  waitingResponse: false,
  showThinking: true,
  onEditClick: () => {},
  onStreamingCompleted: () => {},
  onStream: () => {},
  allowStream: false,
  onThumbClick: () => {}
};

const MemoizedHistory = React.memo(ChatHistory);

export { ChatHistory, MemoizedHistory };
