/* eslint-disable no-case-declarations */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, ButtonOutline, ButtonText } from '@beelineloans/cx-library/src/components/buttons';
import InputField from '@beelineloans/cx-library/src/components/forms/fields/InputField';
import { EmailField, MaskedField, PhoneField, DropdownFieldV2, AddressLookup, FormLabel } from '@beelineloans/cx-library/src/components/forms/fields';
import constants from '@beelineloans/cx-library/src/utils/constants';
import { extractGoogleAddressPart } from '@beelineloans/cx-library/src/utils/helpers';
import { ErrorMessage } from '@beelineloans/cx-library/src/components/typography';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { MessageItem } from './ChatMessage';
import TextStreaming from './TextStreaming';

const QuestionLabel = styled.label`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    padding-bottom: 20px;
    display: inline-block;
  }
`;

const MessageItemWithQuestion = styled(MessageItem)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    .container-inner {
      padding-bottom: 0;
    }
  }
`;

const QuestionResultStyled = css`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  padding-left: 45px;
  margin-top: 20px;
  > * {
    margin: 10px 10px 10px 0;
  }
  .field-container {
    min-width: 100%;
  }
  input {
    border-color: ${(props) => props.theme.colours.border.light} !important;
    font-size: 16px;
    width: 100%;
    line-height: 22px;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    .field-container {
      margin-top: 30px;
      min-width: 400px;
    }
  }
`;

const QuestionResult = styled.div`
  ${QuestionResultStyled}
  #address_lookup {
    margin: 0;
    width: 100%;
    .field-container {
      margin-bottom: 0;
    }
    ul {
      background-color: ${(props) => props.theme.colours.background.cream};
      color: ${(props) => props.theme.colours.text.dark};

      li {
        font-weight: 400;
        font-size: 14px;

        &:hover {
          background: ${(props) => props.theme.colours.background.creamDark};
          color: ${(props) => props.theme.colours.text.dark};
        }
      }
    }
  }
`;

const QuestionResultForm = styled.form`
  margin-top: 0 !important;
  ${QuestionResultStyled}
`;

const FormButton = styled(ButtonOutline)`
  font-size: 16px;
  line-height: 22px;
  padding: 10px 25px;
  min-width: 50px;

  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.colours.background.navy};
    color: ${(props) => props.theme.colours.text.light};
    border-color: ${(props) => props.theme.colours.background.navy};
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    min-width: 85px;
  }
`;

const DisabledInputCSS = css`
  .disabled-input {
    border: 1px solid ${(props) => props.theme.colours.border.dark};
    border-radius: 30px;
    padding: 16.5px 25px;
  }
`;

const NumberInputField = styled(MaskedField)`
  margin-bottom: 0;
  div {
    input {
      font-weight: 500;
    }
    input + span {
      top: 13px;
      @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
        top: 13px;
      }
    }
  }
  ${DisabledInputCSS}

  ${(props) =>
    props.$address &&
    css`
      margin-bottom: 20px;
      min-width: 110px !important;
    `}
`;

const PhoneInputField = styled(PhoneField)`
  margin-bottom: 0;
  div {
    input {
      font-weight: 500;
    }
  }
  ${DisabledInputCSS}
`;

const EmailInputField = styled(EmailField)`
  margin-bottom: 0;
  div {
    input {
      font-weight: 500;
    }
  }
  ${DisabledInputCSS}
`;

const TextInputField = styled(InputField)`
  margin-bottom: 0;
  div {
    input {
      font-weight: 500;
    }
  }
  ${DisabledInputCSS}

  ${(props) =>
    props.$address &&
    css`
      margin-bottom: 20px;
      min-width: 110px !important;
    `}
`;

const DropDownField = styled(DropdownFieldV2)`
  div {
    select {
      border-color: ${(props) => props.theme.colours.border.light};
    }
    button {
      font-weight: 500;
    }
    ul {
      box-shadow: none;
    }
  }
`;

const AddressItem = styled.div`
  ${(props) =>
    !props.$cantFindAddress &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}

  ${DropDownField} {
    min-width: 110px;
    button {
      border: 1px solid ${(props) => props.theme.colours.border.light} !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

const AddressLookupLabel = styled(AddressItem)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    order: 1;
  }
`;

const AddressUnitLabel = styled(AddressItem)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    order: 3;
  }
`;
const AddressLookupItem = styled(AddressItem)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    order: 2;
  }
`;
const AddressUnitItem = styled(AddressItem)`
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    order: 4;
  }
`;

const AddressWrap = styled.div`
  display: grid;
  column-gap: 0px;
  row-gap: 10px;
  width: 100%;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto auto;
  margin-bottom: 20px;
  .field-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .field-container {
    width: 100%;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: auto 100px;
    grid-template-rows: auto auto;
  }
`;

const CantFindAddressWrap = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 10px;
  margin: 0 0 20px 0 !important;
  width: 100%;

  .field-container {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
  }

  div {
    margin: 0 !important;
  }

  div:nth-of-type(1) {
    order: 1;
  }
  div:nth-of-type(2) {
    order: 3;
  }
  div:nth-of-type(3) {
    order: 5;
  }
  div:nth-of-type(4) {
    order: 2;
  }
  div:nth-of-type(5) {
    order: 4;
  }
  div:nth-of-type(6) {
    order: 6;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    div {
      order: unset !important;
    }
  }
`;

const AddressForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  button {
    align-self: flex-end;
    justify-self: flex-end;
  }
`;

const CantFindAddressButton = styled(ButtonText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 30px 10px 0;
  min-width: 180px;
  text-align: right;
`;

const AddressLabel = styled(FormLabel)`
  color: ${(props) => props.theme.colours.text.dark};
`;

const QuestionItem = styled(Container)`
  z-index: 51;
  position: relative;
  .container-inner {
    padding: 0 15px 35px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    max-width: 640px;
    width: 100%;
    z-index: 20;
    position: relative;
    display: flex;
    flex-direction: column;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding: ${(props) => (props.$disabled ? '0 0 35px 0' : '0')};
    }
  }

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.5;
      .field-container.addressLookup {
        margin-top: 0;
      }
    `}
`;

const QuestionErrorMessage = styled(ErrorMessage)`
  padding-left: 45px;
`;

const QuestionButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div:nth-child(2) {
    align-self: end;
    margin-left: 10px;
    margin-top: 20px;
    min-width: 100px;
    button {
      min-width: 100%;
    }
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    flex-direction: row;
    div:nth-child(2) {
      margin-left: 0;
      align-self: end;
      min-width: auto;
      margin-top: 0;
      button {
        min-width: 70px;
      }
    }
  }
`;

const ChatQuoteQuestion = ({ question, onAnswerEntered, addressType, onStreamingCompleted }) => {
  const questionFormRef = useRef(null);
  const addressForm = useRef(null);
  const [address, setAddress] = useState(false);
  const [cantFindAddress, setCantFindAddress] = useState(false);
  const [questionCanContinue, setQuestionCanContinue] = useState(false);
  const [streamComplete, setStreamComplete] = useState(question.disabled);

  let options = null;

  if (!question) return null;

  const setAnswer = (val) => {
    setQuestionCanContinue(false);
    onAnswerEntered(question, val);
  };

  const getKeyValPair = (e) => ({ val: e, text: e });

  const onAddressSelected = (fullAddress, addressFormatted) => {
    setAddress(addressFormatted);
    const addressParts = fullAddress.address_components;
    if (addressParts) {
      const streetNumber = extractGoogleAddressPart(addressParts, 'street_number');
      const streetName = extractGoogleAddressPart(addressParts, 'route');
      if (streetNumber && streetNumber.trim() !== '') {
        onAnswerEntered({ field_name: 'property_street_number' }, getKeyValPair(streetNumber), false);
      }
      if (streetName && streetName.trim() !== '') {
        onAnswerEntered({ field_name: 'property_street' }, getKeyValPair(streetName), false);
      }
      onAnswerEntered({ field_name: 'property_state' }, getKeyValPair(extractGoogleAddressPart(addressParts, 'administrative_area_level_1')?.trim()), false);
      onAnswerEntered({ field_name: 'property_city' }, getKeyValPair(extractGoogleAddressPart(addressParts, 'locality')?.trim()), false);
      onAnswerEntered({ field_name: 'property_zip' }, getKeyValPair(extractGoogleAddressPart(addressParts, 'postal_code')?.trim()), false);
    }

    onAnswerEntered(question, getKeyValPair(addressFormatted), addressType !== 'address');
  };

  const onAddressChange = () => {
    setAddress(null);
  };

  const cantFindAddressClick = () => {
    if (question.disabled) return;
    setCantFindAddress(true);
  };

  const validateManualAddress = () => {
    const street = addressForm?.current?.property_street?.value?.trim();
    const city = addressForm?.current?.property_city?.value.trim();
    const state = addressForm?.current?.property_state?.value.trim();
    const zipCode = addressForm?.current?.property_zip?.value.trim();
    let formatted = null;
    const valid = street !== '' && city !== '' && state !== '' && zipCode !== '';

    if (valid) {
      formatted = `${street}, ${city}, ${state} ${zipCode}, USA`;
      onAnswerEntered({ field_name: 'property_street' }, getKeyValPair(street), false);
      onAnswerEntered({ field_name: 'property_city' }, getKeyValPair(city), false);
      onAnswerEntered({ field_name: 'property_state' }, getKeyValPair(state), false);
      onAnswerEntered({ field_name: 'property_zip' }, getKeyValPair(zipCode), false);

      setAddress(formatted);
    }

    return formatted;
  };

  const onManualAddressItemChange = () => {
    validateManualAddress();
  };

  const onAddressContinueClick = () => {
    if (address && !question.disabled) {
      onAnswerEntered({ field_name: 'property_unit_number' }, getKeyValPair(addressForm?.current?.property_unit_number?.value ?? '-'), false);
      onAnswerEntered(question, getKeyValPair(address));
    }
  };

  const getButtonFields = () => {
    const buttons = [];
    question.possible_answers.forEach((answer) => {
      buttons.push(
        <FormButton
          onClick={() => {
            if (!question.disabled) {
              setAnswer(answer);
            }
          }}
          thin
          key={answer.val}
          disabled={question.disabled}
        >
          {answer.text}
        </FormButton>
      );
    });
    return buttons;
  };

  useEffect(() => {
    // if (questionFormRef.current) questionFormRef.current?.[0].focus();
  }, [streamComplete]);

  const getDropDownFields = (overrideQuestion = null, answerOnSelect = true) => {
    let ddOptions = [];

    if ((overrideQuestion ?? question).field_name === 'property_state') {
      ddOptions = constants.STATES;
    } else {
      ddOptions = question.possible_answers;
    }
    // } else {
    // ddOptions = (overrideQuestion ?? question).possible_answers; //[];
    // (overrideQuestion ?? question).possible_answers.forEach((answer) => {
    //   ddOptions.push({ val: answer, text: answer });
    // });
    // }
    return (
      <DropDownField
        rounded
        id={(overrideQuestion ?? question).field_name}
        options={ddOptions}
        border={false}
        defaultValue=""
        disabled={question.disabled}
        onInputChange={(e) => {
          if (answerOnSelect && !question.disabled) {
            const theAnswer = { val: e.target.value, text: ddOptions.find((o) => o.val === e.target.value)?.text ?? e.target.value };
            setAnswer(theAnswer);
          }
        }}
      />
    );
  };

  const onQuestionContinueClick = () => {
    if (question.disabled) return;

    if (questionFormRef.current?.[0]?.value) setAnswer(getKeyValPair(questionFormRef.current?.[0]?.value));
  };

  const isCurrency = question.field_type === 'currency';

  switch (question.field_type) {
    case 'button':
      options = getButtonFields();
      break;
    case 'dropdown':
      options = getDropDownFields();
      break;

    case 'address':
      // TODO: move address input to its own component
      options = (
        <>
          {addressType === 'address' && (
            <AddressForm
              ref={addressForm}
              onSubmit={(e) => {
                if (question.disabled) return;
                e.preventDefault();
              }}
            >
              <AddressWrap>
                <AddressLookupLabel $cantFindAddress={cantFindAddress}>
                  <AddressLabel>Property Address</AddressLabel>
                  {!cantFindAddress && (
                    <CantFindAddressButton selected onClick={cantFindAddressClick}>
                      Can&apos;t find the address?
                    </CantFindAddressButton>
                  )}
                </AddressLookupLabel>
                <AddressUnitLabel>
                  <AddressLabel>Unit/Apt #</AddressLabel>
                </AddressUnitLabel>
                <AddressLookupItem>
                  {cantFindAddress && (
                    <TextInputField id="property_street" data-hj-allow rounded border={false} onChange={onManualAddressItemChange} $address disabled={question.disabled} />
                  )}
                  {!cantFindAddress && (
                    <div id="address_lookup">
                      <AddressLookup onSelect={onAddressSelected} onChange={onAddressChange} address="" placeType={addressType} autoFocus $address disabled={question.disabled} />
                    </div>
                  )}
                </AddressLookupItem>
                <AddressUnitItem>
                  {cantFindAddress && (
                    <TextInputField id="property_unit_number" data-hj-allow rounded border={false} onChange={onManualAddressItemChange} $address disabled={question.disabled} />
                  )}
                  {!cantFindAddress && <TextInputField id="property_unit_number" data-hj-allow rounded border={false} $address disabled={question.disabled} />}
                </AddressUnitItem>
              </AddressWrap>
              {cantFindAddress && (
                <CantFindAddressWrap>
                  <AddressItem>
                    <AddressLabel>City</AddressLabel>
                  </AddressItem>
                  <AddressItem>
                    <AddressLabel>State</AddressLabel>
                  </AddressItem>
                  <AddressItem>
                    <AddressLabel>Zip code</AddressLabel>
                  </AddressItem>
                  <AddressItem>
                    <TextInputField id="property_city" rounded border={false} data-hj-allow onChange={onManualAddressItemChange} $address disabled={question.disabled} />
                  </AddressItem>
                  <AddressItem>{getDropDownFields({ field_name: 'property_state' }, false)}</AddressItem>
                  <AddressItem>
                    <TextInputField id="property_zip" rounded border={false} data-hj-allow onChange={onManualAddressItemChange} $address disabled={question.disabled} />
                  </AddressItem>
                </CantFindAddressWrap>
              )}
              <Button disabled={question.disabled ? true : !address} onClick={onAddressContinueClick}>
                Continue
              </Button>
            </AddressForm>
          )}
          {addressType !== 'address' && (
            <div id="address_lookup">
              <AddressLookup onSelect={onAddressSelected} address="" placeType={addressType} autoFocus disabled={question.disabled} />
            </div>
          )}
        </>
      );
      break;
    case 'number':
    case 'currency':
      let mask = 'currency';
      if (question.field_name === 'property_zip') {
        mask = { mask: '_____', replacement: { _: /\d/ } };
      } else if (!isCurrency) {
        // mask = { mask: '_____', replacement: { _: /\d/ } };
      }

      options = (
        <NumberInputField
          id={question.field_name}
          prefix={question.disabled ? null : isCurrency ? '$' : null}
          defaultValue=""
          onEnter={(e) => setAnswer(getKeyValPair(e.target.value))}
          onInputChange={(e) => setQuestionCanContinue(e.target?.value?.trim().length > 0)}
          rounded
          border={false}
          autoFocus
          mask={mask}
          data-hj-allow
          disabled={question.disabled}
          // {...numberProps}
        />
      );
      break;
    case 'phone':
      options = (
        <PhoneInputField
          id={question.field_name}
          onEnter={(e) => setAnswer(getKeyValPair(e.target.value))}
          onInputChange={(e) => setQuestionCanContinue(e.target?.value?.trim().length > 0)}
          rounded
          border={false}
          autoFocus
          disabled={question.disabled}
        />
      );
      break;
    case 'email':
      options = (
        <EmailInputField
          id={question.field_name}
          onEnter={(e) => setAnswer(getKeyValPair(e.target.value))}
          onInputChange={(e) => setQuestionCanContinue(e.target?.value?.trim().length > 0)}
          rounded
          border={false}
          autoFocus
          disabled={question.disabled}
        />
      );
      break;
    default:
      options = (
        <TextInputField
          id={question.field_name}
          onEnter={(e) => setAnswer(getKeyValPair(e.target.value))}
          onInputChange={(e) => setQuestionCanContinue(e.target?.value?.trim().length > 0)}
          rounded
          border={false}
          autoFocus
          data-hj-allow
          disabled={question.disabled}
        />
      );
      break;
  }

  return (
    <>
      <MessageItemWithQuestion type="ai" showThumbs={false}>
        <QuestionLabel>
          {question.disabled && <>{question.question}</>}
          {!question.disabled && (
            <TextStreaming
              txtToStream={question.question}
              onStreamingCompleted={() => {
                setStreamComplete(true);
                onStreamingCompleted();
              }}
            />
          )}
        </QuestionLabel>
      </MessageItemWithQuestion>
      {streamComplete && (
        <QuestionItem $disabled={question.disabled}>
          <QuestionButtonGroup>
            {question.field_type === 'address' && <QuestionResult>{streamComplete ? options : null}</QuestionResult>}
            {question.field_type !== 'address' && <QuestionResultForm ref={questionFormRef}>{streamComplete ? options : null}</QuestionResultForm>}
            {!['address', 'button', 'dropdown'].includes(question.field_type) && (
              <div>
                <Button disabled={question.disabled ? question.disabled : !questionCanContinue} onClick={onQuestionContinueClick}>
                  Continue
                </Button>
              </div>
            )}
          </QuestionButtonGroup>
          {question.error && <QuestionErrorMessage>{question.error}</QuestionErrorMessage>}
        </QuestionItem>
      )}
    </>
  );
};

ChatQuoteQuestion.propTypes = {
  question: PropTypes.shape({
    field_name: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    data_type: PropTypes.string.isRequired,
    field_type: PropTypes.string.isRequired,
    min_number: PropTypes.number,
    max_number: PropTypes.number,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    possible_answers: PropTypes.arrayOf(
      PropTypes.shape({
        val: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  onAnswerEntered: PropTypes.func.isRequired,
  addressType: PropTypes.string,
  onStreamingCompleted: PropTypes.func
};

ChatQuoteQuestion.defaultProps = {
  onStreamingCompleted: () => {}
};

export default ChatQuoteQuestion;
