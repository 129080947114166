import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button } from '@beelineloans/cx-library/src/components/buttons';
import CloseCircle from '../../../../shared/images/close-circle.svg';
import { MessageItem } from './ChatMessage';
import TextStreaming from './TextStreaming';

const SummaryWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${(props) => (props.$show ? '1' : '0')}};
  height: ${(props) => (props.$show ? '100% !important' : '0')}};
  transition: all 1s ease-in-out;
  overflow: ${(props) => (props.$show ? 'auto' : 'hidden')}};;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ${(props) =>
    !props.$animate &&
    css`
      transition: none !important;
    `}

    button {
    margin-top: 25px;
    align-self: flex-end;
  }
`;

const SummaryTableWrap = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.theme.colours.background.cream};
`;

const SummaryTableHeader = styled.div`
  background: ${(props) => props.theme.colours.background.navy};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 30px;
  color: ${(props) => props.theme.colours.text.light};
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.colours.border.light};
  }

  tr:last-of-type td {
    border-bottom: 0;
  }
`;

const CloseIcon = styled.span`
  background: url(${CloseCircle}) no-repeat;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
`;

const SummaryContinue = styled.div`
  display: flex;
  flex-direction: column;
  button {
    align-self: end;
    margin-top: 20px;
  }
`;

const SummaryMessageItem = styled(MessageItem)`
  .container-inner {
    padding: 0;
    .message_body {
      margin: 0 10px;
    }
  }
`;

const ChatSummary = ({ msg, summaryContinueClicked, onEditClick, showButtons, isMostRecent, onStreamingCompleted, allowStream }) => {
  const [actionsDisabled, setActionsDisabled] = useState(!showButtons);
  const [streamCompleted, setStreamCompleted] = useState(false);
  const [show, setShow] = useState(!allowStream);

  useEffect(() => {
    if (isMostRecent) {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  const cleanFieldName = (field) => {
    const val = (field ?? '').replace(/_/g, ' ');
    if (val.length === 0) return val;
    const [firstChar, ...remainingChars] = val;
    const joined = `${firstChar.toUpperCase()}${remainingChars.join('')}`;
    return field === 'had_loan_before' ? `${joined}?` : joined;
  };

  if (!msg.data.summary) return null;
  const appType = msg.data.summary.application_type?.toLowerCase().indexOf('refi') > -1 ? 'Refinancing' : 'Buying';
  const address = `${msg.data.summary.property_city} ${msg.data.summary.property_state} ${msg.data.summary.property_zip}`;
  const summaryHeader = `${appType}: ${address}`;

  return (
    <>
      <SummaryMessageItem showIcon={false} showThumbs={false} type="ai">
        <SummaryWrap $show={show || !isMostRecent} $animate={allowStream}>
          <SummaryTableHeader>{summaryHeader}</SummaryTableHeader>
          <SummaryTableWrap>
            <SummaryTable>
              <tbody>
                {Object.entries(msg.data.summary).map(([field, value]) => {
                  if (field === 'full_address') return null;
                  if (field === 'area_looking') return null;
                  if (field === 'start') return null;
                  if ((field === 'property_unit_number' && value === '') || value === null) return null;

                  const isCurrencyField = field.indexOf('amount') > -1 || field.indexOf('value') > -1 || field === 'estimated_rent' || field === 'mortgage_balance';

                  return (
                    <tr key={field}>
                      <td>{cleanFieldName(field)}</td>
                      <td>{isCurrencyField ? `$${value}` : value}</td>
                      <td>
                        <CloseIcon
                          onClick={() => {
                            setActionsDisabled(true);
                            onEditClick(field);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </SummaryTable>
          </SummaryTableWrap>
        </SummaryWrap>
      </SummaryMessageItem>
      {show && (
        <MessageItem type="ai" showThumbs={false} includeBlank={false}>
          <SummaryContinue>
            {isMostRecent && allowStream && (
              <TextStreaming
                txtToStream={msg.content}
                onStreamingCompleted={() => {
                  setStreamCompleted(true);
                  onStreamingCompleted(msg.data?.msgType);
                }}
              />
            )}
            {(!isMostRecent || !allowStream) && <span>{msg.content}</span>}
            {!actionsDisabled && streamCompleted && (
              <Button
                onClick={() => {
                  setActionsDisabled(true);
                  summaryContinueClicked();
                }}
              >
                Looks good &#12297;
              </Button>
            )}
          </SummaryContinue>
        </MessageItem>
      )}
    </>
  );
};

ChatSummary.propTypes = {
  msg: PropTypes.shape().isRequired,
  showButtons: PropTypes.bool.isRequired,
  summaryContinueClicked: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isMostRecent: PropTypes.bool,
  onStreamingCompleted: PropTypes.func,
  allowStream: PropTypes.bool
};

ChatSummary.defaultProps = {
  isMostRecent: false,
  onStreamingCompleted: () => {},
  allowStream: false
};
export default ChatSummary;
