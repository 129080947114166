import React from 'react';
import PropTypes from 'prop-types';
import ChatSummary from './ChatSummary';
import ChatQuote from './ChatQuote';
import ChatQuoteQuestion from './ChatQuoteQuestion';

const ChatHTMLItem = ({ msg, showButtons, onSummaryEditClick, onSummaryContinueClick, onStreamingCompleted, allowStream }) => {
  if (!msg.data || !msg.data.msgType) return null;
  let returnComponent = null;
  switch (msg.data.msgType) {
    case 'summary':
      returnComponent = (
        <ChatSummary
          msg={msg}
          onEditClick={onSummaryEditClick}
          summaryContinueClicked={onSummaryContinueClick}
          showButtons={showButtons}
          isMostRecent={showButtons}
          allowStream={allowStream}
          onStreamingCompleted={onStreamingCompleted}
        />
      );
      break;
    case 'quote':
      returnComponent = <ChatQuote quote={msg.data?.quote} msg={msg} isMostRecent={showButtons} onStreamingCompleted={onStreamingCompleted} allowStream={allowStream} />;
      break;
    case 'question_answer':
      returnComponent = <ChatQuoteQuestion question={{ ...msg.data.question, disabled: true }} onAnswerEntered={() => {}} />;
      break;
    default:
      returnComponent = null;
      break;
  }

  return returnComponent;
};

ChatHTMLItem.propTypes = {
  msg: PropTypes.shape().isRequired,
  showButtons: PropTypes.bool.isRequired,
  onSummaryEditClick: PropTypes.func,
  onSummaryContinueClick: PropTypes.func,
  onStreamingCompleted: PropTypes.func,
  allowStream: PropTypes.bool
};

ChatHTMLItem.defaultProps = {
  onSummaryEditClick: () => {},
  onSummaryContinueClick: () => {},
  onStreamingCompleted: () => {},
  allowStream: false
};

export default ChatHTMLItem;
