import styled from 'styled-components';

const LoadingCursor = styled.span`
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  }
  &::after {
    content: '▋';
    animation: blink 1s steps(5, start) infinite;
  }
`;

export default LoadingCursor;
