import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import LoadingCursor from './LoadingCursor';

const TextStreaming = ({ txtToStream, onStreamingCompleted, onStream, keepBlinking, speedMS }) => {
  if (!txtToStream) return null;

  const [streamedText, setStreamedText] = useState('');
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex >= txtToStream.length - 1) {
        clearInterval(intervalId);
        onStreamingCompleted();
        setCompleted(true);
        return;
      }

      const currentChar = txtToStream[currentIndex] === '\n' ? '<br />' : txtToStream[currentIndex];
      setStreamedText((prevText) => prevText + currentChar);
      currentIndex += 1;
      onStream(txtToStream[currentIndex]);
    }, speedMS);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [txtToStream]);

  return (
    <>
      {completed && <span dangerouslySetInnerHTML={{ __html: txtToStream.replace(/\n/g, '<br/>') }} />}
      {!completed && <span dangerouslySetInnerHTML={{ __html: streamedText }} />}
      {(keepBlinking || !completed) && <LoadingCursor />}
    </>
  );
};

TextStreaming.propTypes = {
  txtToStream: PropTypes.string,
  onStream: PropTypes.func,
  onStreamingCompleted: PropTypes.func,
  keepBlinking: PropTypes.bool,
  speedMS: PropTypes.number
};

TextStreaming.defaultProps = {
  txtToStream: null,
  onStream: () => {},
  onStreamingCompleted: () => {},
  keepBlinking: false,
  speedMS: 30
};

export default TextStreaming;
